import React, { useState } from 'react';
import axios from 'axios';
import './UploadForm.css'; 

function UploadForm({username}) {
    const [files, setFiles] = useState([]);
    const [results, setResults] = useState([]);
    const [textOnly, setTextOnly] = useState(false);
    const [convertToImage, setConvertToImage] = useState(false);
    const [convertToWord, setConvertToWord] = useState(false); 
    const [autoTag, setAutoTag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const invalidFiles = selectedFiles.filter(file => !file.name.endsWith('.pdf'));

        if (invalidFiles.length > 0) {
            setError('Invalid file type. Please upload PDF files only.');
            setFiles([]);
        } else {
            setError(null);
            setFiles(event.target.files);
        }
    };

    const handleTextOnlyChange = (event) => {
        setTextOnly(event.target.checked);
    };

    const handleConvertToImageChange = (event) => {
        setConvertToImage(event.target.checked);
    };

    const handleConvertToWordChange = (event) => {
        setConvertToWord(event.target.checked);
    };

    const handleAutoTagChange = (event) => {
        setAutoTag(event.target.checked);
    };

    const handleSubmit = async (event) => {
        setError('');
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        formData.append('textOnly', textOnly);
        formData.append('convertToImage', convertToImage);
        formData.append('convertToWord', convertToWord);
        formData.append('autoTag', autoTag);
        formData.append('username', username);

        try {
            const response = await axios.post(apiUrl, formData, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'x-filename': files[0].name 
                },
            });
            setResults(response.data);
        } catch (error) {
            if (error.response && error.response.status === 500) {
                setError('Internal server error. Please try again later.');
            } else {
                setError('Error uploading files. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="upload-form-container">
                <div className="upload-form-card">
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="fileUpload" className="file-upload-label">
                            <input
                                type="file"
                                id="fileUpload"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <span className="file-upload-icon">📁</span>
                            <span className="file-upload-text">Select files</span>
                        </label>
                        <div className="file-names">
                            {Array.from(files).map((file, index) => (
                                <div key={index}>{file.name}</div>
                            ))}
                        </div>
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="textOnly"
                                checked={textOnly}
                                onChange={handleTextOnlyChange}
                            />
                            <label htmlFor="textOnly">Text Only</label>
                        </div>
                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                id="convertToImage"
                                checked={convertToImage}
                                onChange={handleConvertToImageChange}
                            />
                            <label htmlFor="convertToImage">Convert to Image</label>
                        </div>
                        <div className="checkbox-container"> {}
                            <input
                                type="checkbox"
                                id="convertToWord"
                                checked={convertToWord}
                                onChange={handleConvertToWordChange}
                            />
                            <label htmlFor="convertToWord">Convert to Word</label>
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <button type="submit" className="upload-button" disabled={loading || files.length === 0}>
                            {loading ? <div className="spinner"></div> : 'Submit'}
                        </button>
                    </form>
                    <div className="results">
                        {results.map((result, index) => (
                            <div key={index}>
                                <a href={`data:application/pdf;base64,${result.fileContent}`} download={result.fileName}>
                                    {result.fileName}
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadForm;
