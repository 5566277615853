// src/msalConfig.js

import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "e42e6485-93aa-4a3f-bfc6-b9139efa3bd3",
        authority: "https://login.microsoftonline.com/eadaaa2f-1d17-482b-8b97-839ec8e97361",
        redirectUri: "https://dev.ocrbot.technology.ca.gov", 
        postLogoutRedirectUri: "https://dev.ocrbot.technology.ca.gov",
    },
    cache: {
        cacheLocation: "localStorage", // This will store tokens in localStorage
        storeAuthStateInCookie: true, // For IE11 or Edge support
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
