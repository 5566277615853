import React from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import msalInstance from './msalConfig';
import UploadForm from './UploadForm';
import './App.css';

function App() {
    const isLocal = process.env.REACT_APP_ENV === 'local';

    return isLocal ? (
        <Main />
    ) : (
        <MsalProvider instance={msalInstance}>
            <Main />
        </MsalProvider>
    );
}

function Main() {
    const isLocal = process.env.REACT_APP_ENV === 'local';
    const { instance, accounts } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect();
    };

    const handleLogout = () => {
        instance.logoutRedirect();
    };

    const username = isLocal ? 'local' : (accounts.length > 0 ? accounts[0].username : 'local');

    return (
        <div className="App">
            <header className="toolbar">
                <h1 className="toolbar-header">OCRBot</h1>
                {!isLocal && accounts.length > 0 ? (
                    <button onClick={handleLogout}>Logout</button>
                ) : !isLocal ? (
                    <button className="login-button" onClick={handleLogin}>Login</button>
                ) : null}
            </header>
            {isLocal || accounts.length > 0 ? (
                <main>
                    <UploadForm username={username}/>
                </main>
            ) : (
                <p>Please log in to use the application.</p>
            )}
        </div>
    );
}

export default App;
